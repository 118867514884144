import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_ex } from '../ts/Utility';
import { navigate } from '../redux/slice/pages';
import { derivate_key, encrypt } from '../ts/crypto';
import { set_key, set_tokens } from '../redux/slice/users';
import ReduxState from '../redux/redux_state';
import Cerberus from '../ts/Cerberus';
import './Home.scss';

export const HomePage: React.FC = () => {

    const dispatch = useDispatch();

    const logout = () => {
        dispatch(navigate(Cerberus.Pages.Login));
    };

    return (
        <>
            <header>
                <label className='logo'>Cerberus web application</label>
                <div className='header-content'>
                    <button onClick={logout}>ログアウト</button>
                </div>
            </header>
            <main className='home'>
                <div>
                    <div>
                        フィルタ
                    </div>
                    <div>
                        <button>
                            よく使う
                        </button>
                        <button>
                            すべて
                        </button>
                        <button>
                            任意
                        </button>
                    </div>

                    <div>
                        並べ替え
                    </div>
                    <div>
                        <button>
                            デフォルト
                        </button>
                        <button>
                            最近使用
                        </button>
                    </div>
                </div>
                <div>
                    <label>データ名</label><input type="text" value="新しいデータ" />
                    <div>
                        <input type="text" className='form-control' />
                    </div>
                </div>
                <div className="container-sm border rounded-1">100% wide until small breakpoint</div>
<div className="container-md border rounded-2">100% wide until medium breakpoint</div>
<div className="container-lg border rounded-3">100% wide until large breakpoint</div>
<div className="container-xl border">100% wide until extra large breakpoint</div>
<div className="container-xxl border">100% wide until extra extra large breakpoint</div>
            </main>
            <footer></footer>
        </>
    );
}