//import { buffer } from "stream/consumers";
import {toByteArray,fromByteArray} from "base64-js";

const iv = Uint8Array.from([0x65, 0x4f, 0xdf, 0x34, 0xd6, 0x9d, 0x03, 0xfc, 0x4f, 0xa0, 0x10, 0xfa]);


export const derivate_key = async (salt: string, iterations: number, password: string): Promise<CryptoKey> => {
    const importedPassword = await window.crypto.subtle.importKey(
        "raw",
        stringToUint8Array(password),
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const key = await window.crypto.subtle.deriveKey({
        name: "PBKDF2",
        salt: stringToUint8Array(salt),
        iterations: iterations,
        hash: "SHA-256"
    },
        importedPassword, {
        name: "AES-GCM",
        length: 128
    },
        false,
        ["encrypt", "decrypt"]
    );

    return key;
};

export const encrypt = async (raw: string, key: CryptoKey) => {
    const encrypted = await window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv: iv,
    }, key, stringToUint8Array(raw));
    const encrypted_data = new Uint8Array(encrypted);
    return uint8ArrayToBase64(encrypted_data);
};

export const decrypt = async (raw: string, key: CryptoKey) => {
    const encrypted_data = base64ToUint8Array(raw);
    const decrypted = await window.crypto.subtle.decrypt({
        name: "AES-GCM",
        iv: iv,
    }, key, encrypted_data);

    return uint8ArrayToString(decrypted);
}

function stringToUint8Array(str: string): Uint8Array {
    return new TextEncoder().encode(str);
}

function uint8ArrayToString(buffer: ArrayBuffer): string {
    return new TextDecoder("utf-8").decode(buffer);
}


function uint8ArrayToBase64(data: Uint8Array): string {
    return fromByteArray(data);
}

function base64ToUint8Array(data: string): Uint8Array {
    return toByteArray(data);
}
