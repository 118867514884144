import { createSlice } from '@reduxjs/toolkit';

export const pages_slice = createSlice({
  name: 'pages',
  initialState: {
    current: 'login',
  },
  reducers: {
    navigate: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { navigate } = pages_slice.actions;

export default pages_slice.reducer;
