import React from 'react';
import { useDispatch } from 'react-redux';
import { fetch_ex } from '../ts/Utility';
import { navigate } from '../redux/slice/pages';
import { set_key } from '../redux/slice/users';
import { decrypt, derivate_key, encrypt } from '../ts/crypto';
import Cerberus from '../ts/Cerberus';
import './Signup.scss';

export const SignupPage: React.FC = () => {
    
// #region fields
    const [user, set_user] = React.useState<string>('');
    const handle_user_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_user(event.target.value);
    };

    const [password, set_password] = React.useState<string>('');
    const handle_password_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_password(event.target.value);
    };

    const [password_confirm, set_password_confirm] = React.useState<string>('');
    const handle_password_confirm_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_password_confirm(event.target.value);
    };
// #endregion

    const dispatch = useDispatch();

    const [processing, setProcessing] = React.useState(false);

    const signup = () => {
        // 入力チェック呼び出し
        setProcessing(true);

        if (validate_page() == false) {
            return;
        }

        login_action();
    }

    const login_action = () => {
        // API呼び出し
        const api: string = '/auth';
        const method = 'POST';
        console.log(user);
        const param = {
            'action' : 'create_user',
            'parameter' : {
                'user' : user
            }
        };

        let token: string = '';
        let auth_key: string = '';
        let plain_auth_key: string = '';
        fetch_ex(api , method, param, false).then(result => {
            console.log('create_user');

            const data = result.parameter;
            const salt = data.salt;
            const iterations = data.iterations;
            token = data.token as string;
            plain_auth_key = data.plain_auth_key;

            console.log(salt);
            console.log(iterations);
            console.log(password);
            console.log(data);
            
            return derivate_key(salt, iterations, password);
        }).then((key) => {
            console.log('derevate_key');
            console.log(key);

            set_key(key);
            
            return encrypt(plain_auth_key, key);
        }).then((result) => {
            console.log('encrypt');

            auth_key = result;
            console.log(auth_key);

            const api: string = '/auth';
            const method = 'POST';
            console.log(user);
            const param = {
                'action' : 'set_auth_key',
                'parameter' : {
                    'user' : user,
                    'auth_key' : auth_key,
                }
            };
            return fetch_ex(api , method, param, false);
        }).then((data)=>{
            console.log('set_auth_key');
            console.log(data);
            
            const api: string = '/auth';
            const method = 'POST';
            const param = {
                'action' : 'login',
                'parameter' : {
                    'user' : user,
                    'token': token,
                    'auth_key' : auth_key,
                }
            };
            return fetch_ex(api , method, param, false);
        }).then((data) => {
            console.log('login');
            console.log(data);

            // TODO: 
        }).catch((reason) => {
            alert(reason);
        });
    
    };

    const signup_click = () => {
        signup();
    };

    const validate_page = () : boolean => {
        if(user === '') {
            alert('ユーザー未入力');
            return false;
        }
        if(password === '') {
            alert('パスワード未入力');
            return false;
        }
        if(password !== password_confirm) {
            alert('パスワード不一致');
            return false;
        }
        return true;
    };

    // const handle_keydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if(event.key === 'Enter') {
    //         login();
    //     }
    // };


  return (
    <>
        <header>
            <label className='logo'>Cerberus web application</label>
            <div className='header-content'>
            </div>
        </header>
        <main className='signup'>
            <div className='signup-box'>
                <div>サインアップ</div>
                
                <div>
                    ユーザー：<input type="text" value={user} onChange={handle_user_change} />
                </div>

                <div>
                    パスワード：<input type="password" value={password} onChange={handle_password_change} />
                </div>
                <div>
                    パスワード確認：<input type="password" value={password_confirm} onChange={handle_password_confirm_change} />
                </div>
                
                <button onClick={signup_click}>サインアップ</button>
                
                <button onClick={()=> dispatch(navigate(Cerberus.Pages.Login))}>ログインへ戻る</button>                
            </div>
        </main>
        <footer></footer>
    </>
  );
}

export default SignupPage;
