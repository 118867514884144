import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_ex } from '../ts/Utility';
import { navigate } from '../redux/slice/pages';
import { derivate_key, encrypt } from '../ts/crypto';
import { set_key, set_tokens } from '../redux/slice/users';
import ReduxState from '../redux/redux_state';
import Cerberus from '../ts/Cerberus';

export const TestPage: React.FC = () => {
    
    const dispatch = useDispatch();

    const back_to_login = () => {
        dispatch(navigate(Cerberus.Pages.Login));
    };



  return (
    <>
        <header>
            <label className='logo'>Cerberus web application</label>
            <div className='header-content'>
            </div>
        </header>
        <main className='container d-flex justify-content-center align-items-center'>




        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Open modal for @mdo</button>
<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@fat">Open modal for @fat</button>
<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">Open modal for @getbootstrap</button>

<div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">New message</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">Recipient:</label>
            <input type="text" className="form-control" id="recipient-name" />
          </div>
          <div className="mb-3">
            <label htmlFor="message-text" className="col-form-label">Message:</label>
            <textarea className="form-control" id="message-text"></textarea>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Send message</button>
      </div>
    </div>
  </div>
</div>



            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <button className='btn btn-primary w-100' onClick={back_to_login}>戻る</button>
                    </div>
                </div>
            </div>
        </main>
        <footer></footer>
    </>
  );
}

export default TestPage;
