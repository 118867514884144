import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import ReduxState from '../redux/redux_state'
import LoginPage from '../pages/Login';
import SignupPage from '../pages/Signup';
import Cerberus from '../ts/Cerberus';
import { HomePage } from '../pages/Home';
import { TestPage } from '../pages/Test';

export const App: React.FC = () => {
  const current_page = useSelector((state: ReduxState) => state.pages.current);

  switch(current_page) {
    case Cerberus.Pages.Login:
      return (<LoginPage></LoginPage>);
    case Cerberus.Pages.Signup:
      return (<SignupPage></SignupPage>);
    case Cerberus.Pages.Home:
      return (<HomePage></HomePage>);
    case Cerberus.Pages.Test:
      return (<TestPage></TestPage>);
  }

  return (<></>);
}

export default App;