import { createSlice } from '@reduxjs/toolkit';

export const users_slice = createSlice({
  name: 'users',
  initialState: {
    key: null,
    access_token: '',
    refresh_token: '',
  },
  reducers: {
    set_key: (state, action) => {
      state.key = action.payload;
    },
    set_tokens: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
  },
});

export const { set_key, set_tokens } = users_slice.actions;

export default users_slice.reducer;
