import { configureStore } from '@reduxjs/toolkit';
import pages_reducer from './slice/pages';
import users_reducer from './slice/users';

export const store = configureStore({
  reducer: {
    pages : pages_reducer,
    users : users_reducer,
  },
});

export default store;