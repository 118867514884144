import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_ex } from '../ts/Utility';
import { navigate } from '../redux/slice/pages';
import { derivate_key, encrypt } from '../ts/crypto';
import { set_key, set_tokens } from '../redux/slice/users';
import ReduxState from '../redux/redux_state';
import Cerberus from '../ts/Cerberus';
import './Login.scss';

export const LoginPage: React.FC = () => {
    
// #region fields
    const [user, set_user] = React.useState<string>('');
    const handle_user_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_user(event.target.value);
    };

    const [password, set_password] = React.useState<string>('');
    const handle_password_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_password(event.target.value);
    };
// #endregion

    const dispatch = useDispatch();

    const [processing, setProcessing] = React.useState(false);

    const login = () => {
        // 入力チェック呼び出し
        setProcessing(true);

        if (validate_page() === false) {
            return;
        }

        login_action();
    };

    const signup = () => {
        dispatch(navigate(Cerberus.Pages.Signup));
    };

    const login_action = () => {

        // API呼び出し
        const api: string = '/auth';
        const method = 'POST';
        const param = {
            'action' : 'pre_login',
            'parameter' : {
                'user' : user
            }
        };

        let token: string = '';
        let auth_key: string = '';
        let plain_auth_key: string = '';
        fetch_ex(api , method, param, false).then(result => {

            const data = result.parameter;
            const salt = data.salt;
            const iterations = data.iterations;
            token = data.token as string;
            plain_auth_key = data.plain_auth_key;

            console.log(salt);
            console.log(iterations);
            console.log(password);
            console.log(data);
            
            return derivate_key(salt, iterations, password);
        }).then((key) => {
            console.log('derevate_key');
            console.log(key);

            set_key(key);
            
            return encrypt(plain_auth_key, key);
        }).then((result) => {
            console.log('encrypt');
            console.log(result);

            auth_key = result;

            const api: string = '/auth';
            const method = 'POST';
            const param = {
                'action' : 'login',
                'parameter' : {
                    'user' : user,
                    'token': token,
                    'auth_key' : auth_key,
                }
            };
            return fetch_ex(api , method, param, false);
        }).then((result) => {
            console.log('login');
            console.log(result);
            const data = result.parameter;

            dispatch(set_tokens({ 
                access_token: data.access_token,
                refresh_token: data.refresh_token,
            }));

            dispatch(navigate(Cerberus.Pages.Home));
        }).catch((reason) => {
            alert(reason);
        });
    };

    const validate_page = () :boolean => {
        if(user === '') {
            alert('ユーザー未入力');
            return false;
        }
        if(password === '') {
            alert('パスワード未入力');
            return false;
        }
        return true;
    };

    const handle_keydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            login();
        }
    };


  return (
    <>
        <header>
            <label className='logo'>Cerberus web application</label>
            <div className='header-content'>
            </div>
        </header>
        <main className='container d-flex justify-content-center align-items-center'>
            <fieldset className='border rounded-3 p-5'>
                <div className='input-fields'>
                    <div className="input-group">
                        <label className="input-group-text">ユーザー</label>
                        <input className="form-control" type="text" placeholder='ユーザー名を入力してください' value={user} onChange={handle_user_change} onKeyDown={handle_keydown} />
                    </div>
                    <div className="input-group">
                        <label className="input-group-text">パスワード</label>
                        <input className="form-control" type="password" placeholder='パスワードを入力してください' value={password} onChange={handle_password_change} onKeyDown={handle_keydown} />
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-primary w-100' onClick={login}>ログイン</button>
                        </div>
                        <div className='col'>
                            <button className='btn btn-primary w-100' onClick={signup}>サインアップ</button>
                        </div>
                        <div className='col'>
                            <button className='btn btn-primary w-100' onClick={()=> dispatch(navigate(Cerberus.Pages.Test))} >テストページ</button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </main>
        <footer></footer>
    </>
  );
}

export default LoginPage;
