import { resolve } from "path";

export const fetch_ex = async (url: string, method: string, param: any, needAuth: boolean) => {
  return needAuth ?
    fetch_ex_auth(url, method, param) :
    fetch_ex_core(url, method, param);
}

const fetch_ex_auth = (url: string, method: string, param: any) => {
  let accessToken = sessionStorage['cerberus_access_token'];
  return fetch_ex_core(url, method, param, accessToken).then(response => {
    if (response.success) {
      return response;
    }

    // TODO: auth
    if (response.httpStatus == 401) {
      console.log("refresh token");
      const refreshToken: string = sessionStorage['cerberus_refresh_token'];
      const refreshParam: any = { 'access_token': accessToken, 'type': 1 };
      return fetch_ex_core('/auth/refresh', 'POST', refreshParam, refreshToken).then(response2 => {
        if (response2.success && response2.status == 200) {
          sessionStorage['cerberus_access_token'] = response2.result.access_token;
          sessionStorage['cerberus_refresh_token'] = response2.result.refresh_token;

          return fetch_ex_core(url, method, param, response2.result.access_token);
        }

        // ログイン画面へ遷移
        window.location.assign('/login');
        return false;
      });
    }

    return response;
  });

}

const fetch_ex_core = async (url: string, method: string, param: any, token?: string) => {
  let full_url: string = process.env.REACT_APP_APIURL + url;
  let requestOptions: any = {
    method: method,
    headers: { 'Content-Type': 'application/json' }
  };

  if (!!token) {
    // 認証情報を追加
    requestOptions.headers['Authorization'] = 'Bearer ' + token;
  }

  //パラメータの渡し方
  if (param) {
    switch (method) {
      case 'GET':
        {
          // QueryStringへ変換
          let p = [];
          for (let param_name in param) {
            p.push(param_name + '=' + encodeURIComponent(param[param_name]));
          }
          //　区切り文字&で連結
          let queryString = p.join('&');
          if (p.length > 0) {
            full_url += '?' + queryString;
          }
        }
        break;

      default:
        requestOptions['body'] = JSON.stringify(param);
    }
  }

  console.log(full_url);
  console.log(requestOptions);

  let httpStatus = 0;
  return fetch(full_url, requestOptions).then(response => {
    httpStatus = response.status;
    console.log(response);
    try {
      return response.json();
    } catch {
      return null;
    }
  }).then(data => {
    let methodResponse: any = data;
    if (data !== null) {
      methodResponse.timeout = methodResponse.status == 504;
      methodResponse.httpStatus = httpStatus;
    } else {
      methodResponse = { is_success: false, httpStatus: httpStatus };
    }
    console.log(methodResponse);
    return methodResponse;
  }).catch(error => {
    return { is_success: false, httpStatus: 401 };
  }).then(data => {
    if( data.is_success === false) {
      // TODO: 理由を付けたい
      throw new Error('fetch is not success');
    }
    return data;
  });
}

export const fetch_ex_put_file = (requestUrl: string, file: File) => {
  const url = requestUrl;
  const request_method: string = 'PUT';

  const formData = new FormData();
  formData.append('file', file);

  const requestOptions = {
    method: request_method,
    body: file
  };
  let httpStatus = 0;
  return fetch(url, requestOptions).then(response => {
    console.log(response);
    return response;
  }).catch(error => {
    console.log(error);
    return { status: 0 };
  });
}


export const getToday = () => {
  return new Date();
};
export const getTomorrow = () => {
  let dt = new Date();
  dt.setDate(dt.getDate() + 1);
  return dt;
};
export const getYesterday = () => {
  let dt = new Date();
  dt.setDate(dt.getDate() - 1);
  return dt;
};
export const parseISO8601 = (text: string) => {
  return formatDate(new Date(Date.parse(text)), 'yyyy/MM/dd HH:mm');
};
export const toISO8601 = (date: Date) => {
  return formatDate(date, 'yyyy-MM-ddTHH:mm:ss+09:00');
}
export const formatDate = (date: Date, format: string) => {
  format = format.replace(/yyyy/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/fff/g, ('00' + date.getMilliseconds()).slice(-3));
  return format;
};

export const trim_cst = (text: string) => {
  let result = text.replace(/[\r\n]/g, '');
  if (result.length >= 21) {
    result = result.slice(0, 21 - 1) + '…';
  }
  return result;
}

export const add_comma = (num: any) => {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}